@charset "UTF-8";
/*foundation base*/
/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: bottom;
  background: transparent; }

body {
  line-height: 1; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main {
  display: block; }

ul, li {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

/**
 * initiallize
 * タグ自体のstyleを記述
 */
html {
  font-family: "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "ヒラギノ丸ゴ ProN W4", "Hiragino Maru Gothic ProN", "ヒラギノ丸ゴ Pro W4", "Hiragino Maru Gothic Pro", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", Sans-Serif;
  color: #303233;
  word-wrap: break-word;
  font-size: 15px;
  line-height: 1.4;
  font-feature-settings: "palt"; }

body {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-text-size-adjust: 100%;
  letter-spacing: 0.1em; }

a {
  text-decoration: none;
  color: #303233; }
  a:hover {
    text-decoration: none;
    opacity: 0.8;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden; }

img {
  width: 100%;
  vertical-align: middle; }

* {
  box-sizing: border-box;
  transition: font-size 0.1s linear;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

blockquote {
  background-color: #EEEFFF;
  padding: 1em 1em 1em 3em;
  position: relative;
  border-left: 3px solid #666; }

blockquote:before {
  content: "“";
  font-size: 600%;
  line-height: 1em;
  color: #999;
  position: absolute;
  left: 0;
  top: 0; }

::selection {
  background: #d4dcd6;
  /* Safari */ }

::-moz-selection {
  background: #d4dcd6;
  /* Firefox */ }

.pci {
  display: inline-block; }
  @media print, screen and (max-width: 767px) {
    .pci {
      display: none; } }

.spi {
  display: none; }
  @media print, screen and (max-width: 767px) {
    .spi {
      display: inline-block; } }

.pc {
  display: block; }
  @media print, screen and (max-width: 767px) {
    .pc {
      display: none; } }

.sp {
  display: none; }
  @media print, screen and (max-width: 767px) {
    .sp {
      display: block; } }

/*foundation vender*/
/*object*/
/*layout*/
.l-header {
  min-width: 1150px;
  margin: 0 auto;
  border-bottom: 4px solid #e60012; }
  @media print, screen and (max-width: 767px) {
    .l-header {
      border-bottom: 0.5vw solid #e60012; } }
  @media print, screen and (max-width: 767px) {
    .l-header {
      min-width: 100%;
      width: 89.33333%; } }
  .l-header__inner {
    padding: 30px 35px;
    box-sizing: border-box; }
    @media print, screen and (max-width: 767px) {
      .l-header__inner {
        padding: 4.47761% 5.22388%; } }
  .l-header__logo {
    width: 214px; }
    @media print, screen and (max-width: 767px) {
      .l-header__logo {
        width: 42.53731%; } }

.l-footer {
  background: #232324;
  width: 100%;
  min-width: 1150px; }
  @media print, screen and (min-width: 768px) {
    .l-footer {
      position: absolute;
      bottom: 0;
      min-height: 400px; } }
  @media print, screen and (max-width: 767px) {
    .l-footer {
      min-width: 89.33333%;
      margin: 0 auto; } }
  .l-footer__inner {
    padding: 95px 0 80px; }
    @media print, screen and (max-width: 767px) {
      .l-footer__inner {
        padding: 7.46269% 0 5.97015%; } }
  .l-footer__about {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 900px;
    margin: 0 auto; }
    @media print, screen and (max-width: 767px) {
      .l-footer__about {
        display: block;
        width: 89.33333%; } }
    .l-footer__aboutInner--01 {
      padding-right: 45px;
      border-right: 1px solid #444444; }
      @media print, screen and (max-width: 767px) {
        .l-footer__aboutInner--01 {
          padding-right: 0;
          border: none; } }
    @media print, screen and (max-width: 767px) {
      .l-footer__aboutInner--02 {
        padding-top: 5.97015%;
        margin-top: 7.46269%;
        border-top: 1px solid #444444; } }
  .l-footer__ttl {
    width: 204px; }
    @media print, screen and (max-width: 767px) {
      .l-footer__ttl {
        width: 30.44776%;
        margin: 0 auto; } }
  .l-footer__btn {
    width: 204px;
    text-align: center;
    border: 1px solid #e60012;
    margin-top: 80px; }
    @media print, screen and (max-width: 767px) {
      .l-footer__btn {
        width: 44.77612%;
        margin: 5.22388% auto 0; } }
    .l-footer__btn a {
      display: block;
      color: #fff;
      padding: 20px 0; }
      @media print, screen and (max-width: 767px) {
        .l-footer__btn a {
          font-size: 12px;
          font-size: 3.12907vw;
          padding: 8.33333% 0 6.66667%; } }
      .l-footer__btn a:hover {
        opacity: 1.0;
        background: #e60012; }
  .l-footer__txt {
    color: #fff;
    line-height: 2.0;
    margin-bottom: 30px; }
    @media print, screen and (max-width: 767px) {
      .l-footer__txt {
        font-size: 12px;
        font-size: 3.12907vw;
        line-height: 1.8;
        letter-spacing: -0.03em;
        margin-bottom: 3.73134%; } }
  .l-footer__info {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 13px;
    margin-top: 20px; }
    @media print, screen and (max-width: 767px) {
      .l-footer__info {
        font-size: 10px;
        font-size: 2.60756vw;
        margin-top: 2.23881%; } }
    .l-footer__infoTtl {
      width: 90px; }
      @media print, screen and (max-width: 767px) {
        .l-footer__infoTtl {
          width: 30%; } }
    .l-footer__infoTxt {
      width: 360px; }
      @media print, screen and (max-width: 767px) {
        .l-footer__infoTxt {
          width: 100%; } }
    .l-footer__infoTel {
      width: 165px; }
      @media print, screen and (max-width: 767px) {
        .l-footer__infoTel {
          width: 100%; } }
  .l-footer__copy {
    border-top: 1px solid #444444; }
    .l-footer__copyInner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 1150px;
      width: 84.18741%;
      margin: 0 auto;
      padding: 20px 0; }
      @media print, screen and (max-width: 767px) {
        .l-footer__copyInner {
          display: block;
          width: 89.33333%;
          text-align: center;
          padding: 5.97015% 0;
          margin: 0 auto; } }
    .l-footer__copyTxt {
      color: #888888; }
      .l-footer__copyTxt--left {
        font-size: 13px; }
        .l-footer__copyTxt--left a {
          color: #888888; }
          .l-footer__copyTxt--left a:hover {
            opacity: 1.0;
            text-decoration: underline; }
        @media print, screen and (max-width: 767px) {
          .l-footer__copyTxt--left {
            font-size: 11px;
            font-size: 2.86832vw; } }
      .l-footer__copyTxt--right {
        font-size: 12px; }
        @media print, screen and (max-width: 767px) {
          .l-footer__copyTxt--right {
            font-size: 9px;
            font-size: 2.34681vw;
            margin-top: 5%; } }

#particles-js-01 {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 0; }

#particles-js-02 {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 0; }

canvas {
  position: absolute;
  z-index: 1;
  top: 0; }

/*pages*/
.kv {
  position: relative;
  min-width: 1150px; }
  @media print, screen and (max-width: 767px) {
    .kv {
      min-width: 100%; } }
  .kv__inner {
    position: relative;
    background: url(/assets/img/kv.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 50vw;
    max-height: 683px;
    padding-top: 683px;
    overflow: hidden; }
    @media print, screen and (min-width: 1367px) {
      .kv__inner {
        position: relative;
        background: url(/assets/img/kv_large.png);
        background-size: cover;
        background-position: right;
        background-repeat: no-repeat; } }
    @media print, screen and (max-width: 767px) {
      .kv__inner {
        background: url(/assets/img/kv--sp.png);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        width: 100%;
        height: auto;
        max-height: 100%;
        padding-top: 141.33333%; } }
  .kv__logo {
    position: absolute;
    width: 214px;
    top: 30px;
    left: 35px; }
    @media print, screen and (max-width: 767px) {
      .kv__logo {
        width: 38%;
        top: 4.66667%;
        left: 5.33333%; } }
  .kv__txt {
    position: absolute;
    width: 1080px;
    top: 50%;
    transform: translateY(-65%);
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 5; }
    @media print, screen and (max-width: 767px) {
      .kv__txt {
        width: 100%;
        top: 55%;
        transform: none; } }
    .kv__txtInner {
      width: 646px; }
      @media print, screen and (max-width: 767px) {
        .kv__txtInner {
          width: 81.6%;
          margin: 0 auto; } }
  .kv__polygon--01 {
    width: 50%;
    height: 100vw;
    left: 0;
    transform: rotate(-35deg) translateX(-5%) translatey(-10%); }
    @media print, screen and (max-width: 767px) {
      .kv__polygon--01 {
        width: 90vw;
        height: 175vw;
        transform: rotate(-35deg) translateX(-30%) translatey(-10%); } }
  .kv__polygon--02 {
    width: 50%;
    height: 100vw;
    right: 0;
    transform: rotate(-60deg) translateX(120%) translatey(0%); }
    @media print, screen and (max-width: 767px) {
      .kv__polygon--02 {
        width: 50vw;
        transform: rotate(-70deg) translateX(120%) translatey(0%); } }

.topnews {
  position: absolute;
  width: 51.61054%;
  bottom: -2px;
  right: 0;
  background: url(/assets/img/news_bg.png); }
  @media print, screen and (max-width: 767px) {
    .topnews {
      background: url(/assets/img/news_bg--sp.png);
      background-size: cover;
      background-position: left;
      background-repeat: no-repeat;
      width: 100%;
      height: 9%;
      padding: 3% 0 0 0; } }
  .topnews__bg {
    background: #fff;
    margin-left: 50px; }
    @media print, screen and (max-width: 767px) {
      .topnews__bg {
        background: none;
        margin-left: 0; } }
  .topnews__inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 560px;
    margin-left: 3%;
    padding: 30px 0 20px; }
    @media print, screen and (max-width: 767px) {
      .topnews__inner {
        width: 86.66667%;
        margin-left: 9.33333%;
        padding: 0; } }
  .topnews__list {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media print, screen and (max-width: 767px) {
      .topnews__list {
        width: 80%;
        justify-content: flex-start; } }
    .topnews__listItem--01 {
      font-size: 18px;
      font-weight: bold;
      margin-right: 20px; }
      @media print, screen and (max-width: 767px) {
        .topnews__listItem--01 {
          font-size: 14px;
          font-size: 3.65059vw;
          margin-right: 5%; } }
    .topnews__listItem--02 {
      font-size: 14px;
      padding-left: 20px;
      border-left: 1px solid #d4d5d4; }
      @media print, screen and (max-width: 767px) {
        .topnews__listItem--02 {
          display: none; } }
    .topnews__listItem--03 {
      font-size: 14px;
      margin-left: 20px; }
      @media print, screen and (max-width: 767px) {
        .topnews__listItem--03 {
          display: none; } }
    @media print, screen and (max-width: 767px) {
      .topnews__listItem--04 {
        font-size: 12px;
        font-size: 3.12907vw;
        line-height: 1.5;
        width: 75%;
        padding-left: 5%;
        border-left: 1px solid #d4d5d4; } }
  .topnews__link a {
    color: #e60012;
    font-size: 16px;
    font-weight: bold; }
    @media print, screen and (max-width: 767px) {
      .topnews__link a {
        font-size: 13px;
        font-size: 3.38983vw; } }
    .topnews__link a:hover {
      opacity: 1.0;
      text-decoration: underline; }
    .topnews__link a span {
      position: relative;
      padding-right: 15px; }
      @media print, screen and (max-width: 767px) {
        .topnews__link a span {
          padding-right: 2vw; } }
      .topnews__link a span:after {
        position: absolute;
        content: '';
        display: block;
        top: 40%;
        right: 6px;
        width: 6px;
        padding-top: 6px;
        border-top: 1px solid #e60012;
        border-right: 1px solid #e60012;
        transform: rotate(45deg) translateY(-50%); }
        @media print, screen and (max-width: 767px) {
          .topnews__link a span:after {
            top: 45%;
            width: 1.5vw;
            padding-top: 1.5vw; } }

.btm {
  background: #fff;
  padding-bottom: 140px; }
  @media print, screen and (max-width: 767px) {
    .btm {
      padding-bottom: 18.66667%; } }

.main {
  min-width: 1150px;
  background: #f0f0f0; }
  @media print, screen and (min-width: 768px) {
    .main {
      padding-bottom: 398px; } }
  @media print, screen and (max-width: 767px) {
    .main {
      min-width: 100%;
      overflow: hidden; } }
  .main__ttl {
    position: relative;
    width: 100%; }
    @media print, screen and (max-width: 767px) {
      .main__ttl {
        background: #fff;
        padding: 17.33333% 0 33.33333%; } }
    .main__ttl:before {
      position: absolute;
      content: '';
      display: block;
      width: 50%;
      height: 356px;
      top: 0;
      right: 0;
      background: #fff; }
      @media print, screen and (max-width: 767px) {
        .main__ttl:before {
          display: none; } }
    .main__ttlBg {
      display: flex;
      align-items: center;
      justify-content: space-between; }
    .main__ttlArea {
      position: relative;
      max-width: 1150px;
      margin: 0 auto; }
      @media print, screen and (max-width: 767px) {
        .main__ttlArea {
          max-width: 100%;
          width: 89.33333%; } }
    .main__ttlBg-left {
      position: relative;
      width: 20.86384%;
      height: 357px;
      background: none;
      padding: 110px 0 100px; }
      @media print, screen and (max-width: 767px) {
        .main__ttlBg-left {
          height: auto;
          padding: 0; } }
      .main__ttlBg-left:before {
        position: absolute;
        content: '';
        display: block;
        width: 0;
        height: 0;
        top: 0;
        right: 0;
        border-style: solid;
        border-width: 0 0 358px 234px;
        border-color: transparent transparent #ffffff transparent; }
        @media print, screen and (max-width: 767px) {
          .main__ttlBg-left:before {
            display: none; } }
    .main__ttlBg-right {
      width: 79.13616%;
      height: 358px;
      background: #fff;
      padding: 110px 0 100px; }
      @media print, screen and (max-width: 767px) {
        .main__ttlBg-right {
          display: none;
          height: auto;
          padding: 0; } }
    .main__ttlInner {
      position: absolute;
      top: 50%;
      left: 100px;
      transform: translateY(-50%);
      z-index: 5; }
      @media print, screen and (max-width: 767px) {
        .main__ttlInner {
          position: inherit;
          top: inherit;
          left: 0;
          transform: none; } }
    .main__ttlImg {
      width: 433px; }
      @media print, screen and (max-width: 767px) {
        .main__ttlImg {
          width: 68.0597%; } }
    .main__ttlTxt {
      color: #232324;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0.3em;
      margin-top: 20px; }
      @media print, screen and (max-width: 767px) {
        .main__ttlTxt {
          font-size: 14px;
          font-size: 3.65059vw;
          margin-top: 3.73134%; } }

.pdt {
  position: relative; }
  .pdt--02 {
    margin-top: -45px; }
    @media print, screen and (max-width: 767px) {
      .pdt--02 {
        margin-top: -14.66667%; } }
  @media print, screen and (max-width: 767px) {
    .pdt--03 {
      margin-top: -14.66667%; } }
  .pdt__bg {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 0 auto; }
    .pdt__bg--01 {
      background: #fff; }
    .pdt__bg--02 {
      align-items: flex-start; }
      @media print, screen and (max-width: 767px) {
        .pdt__bg--02 {
          align-items: flex-end; } }
    .pdt__bgInner--left01 {
      position: relative;
      background: #fff;
      width: 45%;
      height: 386px;
      top: 2px;
      padding: 65px 0 85px;
      box-sizing: border-box;
      z-index: 2; }
      @media print, screen and (max-width: 767px) {
        .pdt__bgInner--left01 {
          width: 30.93333%;
          height: 43.73333vw;
          padding: 0; } }
      .pdt__bgInner--left01:before {
        position: absolute;
        content: '';
        display: block;
        width: 0;
        height: 0;
        top: 0;
        right: -253px;
        border-style: solid;
        border-width: 386px 254px 0 0;
        border-color: #ffffff transparent transparent transparent; }
        @media print, screen and (max-width: 767px) {
          .pdt__bgInner--left01:before {
            border-width: 44vw 28.53333vw 0 0;
            right: -28.4vw; } }
      .pdt__bgInner--left01:after {
        position: absolute;
        content: '';
        display: block;
        width: 100%;
        height: 20px;
        bottom: -20px;
        background: #fff; }
        @media print, screen and (max-width: 767px) {
          .pdt__bgInner--left01:after {
            display: none; } }
    .pdt__bgInner--right01 {
      position: relative;
      background: #232324;
      width: 50%;
      height: 429px;
      z-index: 1; }
      @media print, screen and (max-width: 767px) {
        .pdt__bgInner--right01 {
          width: 44.66667%;
          height: 57.2vw; } }
      .pdt__bgInner--right01:before {
        position: absolute;
        content: '';
        display: block;
        width: 0;
        height: 0;
        top: 0;
        left: -276px;
        border-style: solid;
        border-width: 0 0 429px 277px;
        border-color: transparent transparent #232324 transparent; }
        @media print, screen and (max-width: 767px) {
          .pdt__bgInner--right01:before {
            left: -36.8vw;
            border-width: 0 0 57.2vw 36.93333vw; } }
    .pdt__bgInner--left02 {
      position: relative;
      background: #232324;
      width: 90%;
      height: 388px;
      padding: 65px 0 85px;
      box-sizing: border-box;
      z-index: 1; }
      @media print, screen and (max-width: 767px) {
        .pdt__bgInner--left02 {
          width: 43.46667%;
          height: 57.2vw;
          padding: 0; } }
      .pdt__bgInner--left02:before {
        position: absolute;
        content: '';
        display: block;
        width: 0;
        height: 0;
        top: 0;
        right: -253px;
        border-style: solid;
        border-width: 386px 254px 0 0;
        border-color: #232324 transparent transparent transparent; }
        @media print, screen and (max-width: 767px) {
          .pdt__bgInner--left02:before {
            border-width: 57.2vw 37.73333vw 0 0;
            right: -37.6vw; } }
    .pdt__bgInner--right02 {
      position: relative;
      background: #fff;
      width: 60%;
      height: 466px;
      margin-top: 45px;
      z-index: 1; }
      @media print, screen and (max-width: 767px) {
        .pdt__bgInner--right02 {
          width: 44.66667%;
          height: 43.46667vw; } }
      .pdt__bgInner--right02:before {
        position: absolute;
        content: '';
        display: block;
        width: 0;
        height: 0;
        top: 0;
        left: -276px;
        border-style: solid;
        border-width: 0 0 466px 277px;
        border-color: transparent transparent #fff transparent; }
        @media print, screen and (max-width: 767px) {
          .pdt__bgInner--right02:before {
            left: -27.46667vw;
            border-width: 0 0 43.5vw 27.6vw; } }
    .pdt__bgInner--left03 {
      position: relative;
      background: #fff;
      width: 45%;
      height: 365px;
      top: 2px;
      padding: 65px 0 85px;
      box-sizing: border-box;
      z-index: 2; }
      @media print, screen and (max-width: 767px) {
        .pdt__bgInner--left03 {
          width: 30.93333%;
          height: 43.73333vw;
          padding: 0; } }
      .pdt__bgInner--left03:before {
        position: absolute;
        content: '';
        display: block;
        width: 0;
        height: 0;
        top: 0;
        right: -253px;
        border-style: solid;
        border-width: 365px 254px 0 0;
        border-color: #ffffff transparent transparent transparent; }
        @media print, screen and (max-width: 767px) {
          .pdt__bgInner--left03:before {
            border-width: 44vw 28.53333vw 0 0;
            right: -28.4vw; } }
    .pdt__bgInner--right03 {
      position: relative;
      background: #232324;
      width: 50%;
      height: 409px;
      z-index: 1; }
      @media print, screen and (max-width: 767px) {
        .pdt__bgInner--right03 {
          width: 44.66667%;
          height: 57.2vw; } }
      .pdt__bgInner--right03:before {
        position: absolute;
        content: '';
        display: block;
        width: 0;
        height: 0;
        top: 0;
        left: -276px;
        border-style: solid;
        border-width: 0 0 409px 277px;
        border-color: transparent transparent #232324 transparent; }
        @media print, screen and (max-width: 767px) {
          .pdt__bgInner--right03:before {
            left: -36.8vw;
            border-width: 0 0 57.2vw 36.93333vw; } }
  .pdt__con {
    position: absolute;
    width: 1150px;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translateY(-50%);
    z-index: 2; }
    @media print, screen and (max-width: 767px) {
      .pdt__con {
        width: 100%;
        top: 0;
        transform: none; } }
    .pdt__con--02 {
      top: 45%; }
    @media print, screen and (max-width: 767px) {
      .pdt__conBox--01 {
        position: absolute;
        width: 43.73134%;
        top: -15vw;
        left: 0; } }
    .pdt__conBox--02 {
      margin-top: 120px; }
      @media print, screen and (max-width: 767px) {
        .pdt__conBox--02 {
          position: absolute;
          width: 55.22388%;
          top: 12vw;
          right: 0;
          margin-top: 0; } }
    .pdt__conBox--03 {
      margin-top: 40px; }
      @media print, screen and (max-width: 767px) {
        .pdt__conBox--03 {
          position: absolute;
          width: 43.73134%;
          top: -26vw;
          left: 0; } }
    .pdt__conInner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto; }
      @media print, screen and (max-width: 767px) {
        .pdt__conInner {
          display: block; } }
      .pdt__conInner--01 {
        width: 1084px; }
        @media print, screen and (max-width: 767px) {
          .pdt__conInner--01 {
            position: relative;
            width: 89.33333%;
            margin: 0 auto; } }
      .pdt__conInner--02 {
        width: 1070px; }
        @media print, screen and (max-width: 767px) {
          .pdt__conInner--02 {
            position: relative;
            width: 89.33333%;
            margin: 0 auto; } }
      .pdt__conInner--03 {
        width: 946px; }
        @media print, screen and (max-width: 767px) {
          .pdt__conInner--03 {
            position: relative;
            width: 89.33333%;
            margin: 0 auto; } }
    .pdt__conTtl--01 {
      width: 273px; }
      @media print, screen and (max-width: 767px) {
        .pdt__conTtl--01 {
          width: 100%; } }
    .pdt__conTtl--02 {
      width: 273px; }
      @media print, screen and (max-width: 767px) {
        .pdt__conTtl--02 {
          width: 79.18919%; } }
    .pdt__conTtl--03 {
      width: 273px; }
      @media print, screen and (max-width: 767px) {
        .pdt__conTtl--03 {
          width: 100%; } }
    .pdt__conSubttl--01 {
      font-size: 24px;
      font-weight: bold;
      margin-top: 25px; }
      @media print, screen and (max-width: 767px) {
        .pdt__conSubttl--01 {
          font-size: 14px;
          font-size: 3.65059vw;
          margin-top: 10.23891%; } }
    .pdt__conSubttl--02 {
      font-size: 24px;
      font-weight: bold;
      margin-top: 25px; }
      @media print, screen and (max-width: 767px) {
        .pdt__conSubttl--02 {
          font-size: 14px;
          font-size: 3.65059vw;
          margin-top: 10.23891%; } }
    .pdt__conSubttl--03 {
      font-size: 24px;
      font-weight: bold;
      margin-top: 25px; }
      @media print, screen and (max-width: 767px) {
        .pdt__conSubttl--03 {
          font-size: 14px;
          font-size: 3.65059vw;
          margin-top: 10.23891%; } }
    .pdt__conTxt {
      font-size: 18px;
      line-height: 1.5;
      margin-top: 15px;
      color: #000; }
      @media print, screen and (max-width: 767px) {
        .pdt__conTxt {
          font-size: 12px;
          font-size: 3.12907vw;
          line-height: 1.8;
          margin-top: 6.82594%; } }
      @media print, screen and (max-width: 767px) {
        .pdt__conTxt--02 {
          letter-spacing: -0.05em; } }
    .pdt__conImg--01 {
      margin-bottom: 80px; }
      @media print, screen and (max-width: 767px) {
        .pdt__conImg--01 {
          position: absolute;
          top: -5vw;
          right: 0;
          width: 53.58209%;
          margin-bottom: 0; } }
    .pdt__conImg--02 {
      margin-bottom: 0; }
      @media print, screen and (max-width: 767px) {
        .pdt__conImg--02 {
          position: absolute;
          top: -32vw;
          left: 0;
          width: 79.25373%;
          margin-bottom: 0; } }
    .pdt__conImg--03 {
      margin-bottom: 30px; }
      @media print, screen and (max-width: 767px) {
        .pdt__conImg--03 {
          position: absolute;
          top: -6vw;
          right: 0;
          width: 32.68657%;
          margin-bottom: 0; } }

.mgn {
  max-width: 1366px;
  width: 100%;
  margin: 0 auto; }
  @media print, screen and (max-width: 767px) {
    .mgn {
      max-width: 100%; } }

.news {
  min-width: 1150px;
  margin: 0 auto; }
  @media print, screen and (min-width: 768px) {
    .news {
      min-height: 94vh;
      padding-bottom: 398px; } }
  @media print, screen and (max-width: 767px) {
    .news {
      min-width: 100%;
      min-height: 100%; } }
  .news__inner {
    width: 900px;
    margin: 0 auto;
    padding: 90px 0 100px; }
    @media print, screen and (max-width: 767px) {
      .news__inner {
        width: 89.33333%;
        padding: 14.92537% 0 19.40299%; } }
  .news__ttl {
    font-size: 30px; }
    @media print, screen and (max-width: 767px) {
      .news__ttl {
        font-size: 22px;
        font-size: 5.73664vw; } }
  .news__list {
    display: flex;
    align-items: center;
    font-size: 15px;
    line-height: 1.5;
    margin-top: 70px; }
    @media print, screen and (max-width: 767px) {
      .news__list {
        font-size: 14px;
        font-size: 3.65059vw;
        margin-top: 10.44776%; } }
    @media print, screen and (max-width: 767px) {
      .news__list {
        display: block; } }
    .news__listItem {
      position: relative; }
      .news__listItem:first-child {
        margin-right: 20px;
        padding-left: 10px; }
        @media print, screen and (max-width: 767px) {
          .news__listItem:first-child {
            margin-right: 0;
            padding-left: 2%; } }
        .news__listItem:first-child:before {
          position: absolute;
          display: block;
          content: '';
          top: 0;
          left: 0;
          width: 2px;
          padding-top: 22px;
          background: #e60012; }
          @media print, screen and (max-width: 767px) {
            .news__listItem:first-child:before {
              padding-top: 5%; } }
      @media print, screen and (max-width: 767px) {
        .news__listItem:nth-of-type(2) {
          padding-left: 10px; } }
  @media print, screen and (max-width: 767px) and (max-width: 767px) {
    .news__listItem:nth-of-type(2) {
      padding-left: 2%; } }
  .news__subttl {
    position: relative;
    font-size: 20px;
    line-height: 1.2;
    padding-left: 10px; }
    @media print, screen and (max-width: 767px) {
      .news__subttl {
        font-size: 17px;
        font-size: 4.43286vw; } }
    .news__subttl:before {
      position: absolute;
      display: block;
      content: '';
      top: 0;
      left: 0;
      width: 2px;
      padding-top: 22px;
      background: #e60012; }
    .news__subttl.mt80 {
      margin-top: 80px; }
      @media print, screen and (max-width: 767px) {
        .news__subttl.mt80 {
          margin-top: 10.44776%; } }
    .news__subttl.mt60 {
      margin-top: 60px; }
      @media print, screen and (max-width: 767px) {
        .news__subttl.mt60 {
          margin-top: 10.44776%; } }

.privacy {
  min-width: 1150px;
  margin: 0 auto; }
  @media print, screen and (min-width: 768px) {
    .privacy {
      min-height: 94vh;
      padding-bottom: 398px; } }
  @media print, screen and (max-width: 767px) {
    .privacy {
      min-width: 100%; } }
  .privacy__inner {
    width: 900px;
    margin: 0 auto;
    padding: 90px 0 100px; }
    @media print, screen and (max-width: 767px) {
      .privacy__inner {
        width: 89.33333%;
        padding: 14.92537% 0 19.40299%; } }
  .privacy__ttl {
    font-size: 30px; }
    @media print, screen and (max-width: 767px) {
      .privacy__ttl {
        font-size: 22px;
        font-size: 5.73664vw; } }
  .privacy__subttl {
    position: relative;
    font-size: 20px;
    line-height: 1.2;
    padding-left: 10px; }
    @media print, screen and (max-width: 767px) {
      .privacy__subttl {
        font-size: 17px;
        font-size: 4.43286vw; } }
    .privacy__subttl:before {
      position: absolute;
      display: block;
      content: '';
      top: 0;
      left: 0;
      width: 2px;
      padding-top: 22px;
      background: #e60012; }
    .privacy__subttl.mt80 {
      margin-top: 80px; }
      @media print, screen and (max-width: 767px) {
        .privacy__subttl.mt80 {
          margin-top: 10.44776%; } }
    .privacy__subttl.mt60 {
      margin-top: 60px; }
      @media print, screen and (max-width: 767px) {
        .privacy__subttl.mt60 {
          margin-top: 10.44776%; } }
  .privacy__txt {
    font-size: 15px;
    line-height: 2.0;
    margin-top: 20px; }
    @media print, screen and (max-width: 767px) {
      .privacy__txt {
        font-size: 14px;
        font-size: 3.65059vw;
        line-height: 1.8;
        margin-top: 2.98507%; } }
  .privacy__day {
    font-size: 15px;
    line-height: 2.0;
    margin-top: 40px; }
    @media print, screen and (max-width: 767px) {
      .privacy__day {
        font-size: 14px;
        font-size: 3.65059vw;
        line-height: 1.8;
        margin-top: 5.97015%; } }

/*anime*/
.kv__txtInner {
  position: relative;
  opacity: 0; }

@media print, screen and (min-width: 768px) {
  .main__ttlInner {
    opacity: 0;
    top: 40%; } }

@media print, screen and (max-width: 767px) {
  .main__ttlInner {
    position: relative;
    opacity: 0;
    top: -50px; } }

@media print, screen and (min-width: 768px) {
  .pdt__conBox--01 {
    position: relative;
    opacity: 0;
    left: -50px; } }

@media print, screen and (max-width: 767px) {
  .pdt__conBox--01 {
    left: -50px;
    opacity: 0; } }

@media print, screen and (min-width: 768px) {
  .pdt__conBox--02 {
    position: relative;
    opacity: 0;
    right: -50px; } }

@media print, screen and (max-width: 767px) {
  .pdt__conBox--02 {
    opacity: 0;
    right: -50px; } }

@media print, screen and (min-width: 768px) {
  .pdt__conBox--03 {
    position: relative;
    opacity: 0;
    left: -50px; } }

@media print, screen and (max-width: 767px) {
  .pdt__conBox--03 {
    opacity: 0;
    left: -50px; } }

@media print, screen and (min-width: 768px) {
  .pdt__conImg--01 {
    position: relative;
    opacity: 0;
    right: -50px; } }

@media print, screen and (max-width: 767px) {
  .pdt__conImg--01 {
    opacity: 0;
    right: -50px; } }

@media print, screen and (min-width: 768px) {
  .pdt__conImg--02 {
    position: relative;
    opacity: 0;
    left: -50px; } }

@media print, screen and (max-width: 767px) {
  .pdt__conImg--02 {
    opacity: 0;
    left: -50px; } }

@media print, screen and (min-width: 768px) {
  .pdt__conImg--03 {
    position: relative;
    opacity: 0;
    right: -50px; } }

@media print, screen and (max-width: 767px) {
  .pdt__conImg--03 {
    opacity: 0;
    right: -50px; } }
